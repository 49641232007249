.form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 5px;
}

.form-input {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.form-textarea {
    display: block;
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.form-button {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.form-button:hover {
    background-color: #45a049;
}

.form-select {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Style for error messages */
.error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

/* Style for input fields with errors */
input.error {
    border-color: red;
}

/* Style for textarea with errors */
textarea.error {
    border-color: red;
}

link-message {
    border-color: #3c763d;
}

.success-message {
    background-color: #dff0d8; /* Background color */
    color: #3c763d; /* Text color */
    border: 1px solid #d6e9c6; /* Border */
    padding: 10px 15px; /* Padding */
    border-radius: 5px; /* Border radius */
}

.loading-spinner {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3c763d;
    animation: spin 1s linear infinite;

}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
